import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
//components
import Card from '@/components/Card/Card.vue';
import WishlistModal from '@/views/Wishlists/Components/WishlistModal.vue';
import IndicativeRate from '@/views/Packages/Components/IndicativeRate/IndicativeRate.vue';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
import Btn from '@/components/Button/Btn.vue';
let PackageCard = class PackageCard extends Vue {
    constructor() {
        super(...arguments);
        this.singlePackage = this.package;
        this.showWishlistDropdown = false;
        this.isPackageInWishlists = false;
        this.showWishlistModal = false;
        this.showDeleteModal = false;
        this.disabled = false;
        this.loading = false;
        this.error = '';
        this.form = {
            wishlistable_type: 'package',
            wishlistable_id: this.singlePackage.id,
            action: 'remove',
            wishlist_ids: [],
        };
    }
    get indicativeRates() {
        return this.singlePackage && this.singlePackage.rates
            ? this.singlePackage.rates.filter((rate) => rate.indication_rate === true)
            : [];
    }
    async mounted() {
        if (this.$store.getters['auth/loggedIn']) {
            await this.$store.dispatch('wishlists/getWishlists');
        }
        this.singlePackage = this.package;
    }
    async click() {
        await this.$store.dispatch('packages/setActivePackage', this.singlePackage);
        this.$router.push({ path: `/package/${this.singlePackage.slug}` });
    }
    getCoverImage() {
        if (this.singlePackage && this.singlePackage.hero_image && this.singlePackage.hero_image.public_url) {
            return this.singlePackage.hero_image.public_url;
        }
        else if (this.singlePackage.images && this.singlePackage.images.length > 0) {
            return this.singlePackage.images[0].public_url;
        }
        else {
            return null;
        }
    }
    getCoverImageCopyright() {
        if (this.singlePackage && this.singlePackage.hero_image && this.singlePackage.hero_image.public_url && this.singlePackage.hero_image_copyright) {
            return this.singlePackage.hero_image_copyright;
        }
        else {
            return '';
        }
    }
    async pushHistoryState(url) {
        await history.pushState({ search: this.$store.state.packages.searchTerm, page: this.$store.state.packages.page }, 'Packages', this.$route.fullPath);
        this.$router.push({ path: url });
    }
    async setIconAction() {
        if (this.iconAction === 'dropdown') {
            return this.toggleWishlistDropdown();
        }
        if (this.iconAction === 'delete') {
            return this.openDeleteModal();
        }
    }
    async toggleWishlistDropdown() {
        this.showWishlistDropdown = !this.showWishlistDropdown;
        // Check if package is already in user wishlists
        this.wishlists.forEach((wishlist) => {
            wishlist.packages?.forEach((singlePackage) => {
                if (singlePackage.id === this.singlePackage.id) {
                    this.isPackageInWishlists = true;
                }
            });
        });
    }
    async openWishlistModal() {
        if (!this.$store.getters['auth/loggedIn']) {
            // this.showSignInOverlay = true;
            this.$store.dispatch('notification/showErrorModalGuest', "Please register or login to use this feature.");
            return;
        }
        this.showWishlistModal = true;
        this.showWishlistDropdown = false;
    }
    async setLoadingEvent(payload) {
        this.showWishlistModal = false;
        this.isPackageInWishlists = false;
    }
    async openDeleteModal() {
        this.showDeleteModal = true;
    }
    async closeDeleteModal() {
        this.showDeleteModal = false;
    }
    async removePackage() {
        if (this.disabled) {
            return;
        }
        let formData = new FormData();
        // append form data
        formData.append('wishlistable_type', this.form.wishlistable_type);
        formData.append('wishlistable_id', this.form.wishlistable_id);
        // append action
        formData.append('action', this.form.action);
        // append the only wishlist_id
        formData.append('wishlist_ids[]', this.wishlist.id);
        try {
            this.loading = true;
            this.disabled = true;
            const resp = await this.$store.dispatch('wishlists/addOrRemoveItem', formData);
            this.disabled = false;
            this.showDeleteModal = false;
            this.$emit('remove-package');
            this.loading = false;
        }
        catch (e) {
            this.disabled = false;
            this.loading = false;
            this.error = e.message;
            this.$emit('loading-event', { loading: this.loading });
        }
    }
};
__decorate([
    Getter('getWishlists', { namespace: 'wishlists' })
], PackageCard.prototype, "wishlists", void 0);
__decorate([
    Getter('activeWishlist', { namespace: 'wishlists' })
], PackageCard.prototype, "wishlist", void 0);
__decorate([
    Prop()
], PackageCard.prototype, "package", void 0);
__decorate([
    Prop({ default: false })
], PackageCard.prototype, "btn", void 0);
__decorate([
    Prop({ default: '' })
], PackageCard.prototype, "icon", void 0);
__decorate([
    Prop({ default: '' })
], PackageCard.prototype, "iconAction", void 0);
PackageCard = __decorate([
    Component({
        components: { Card, IndicativeRate, MarkdownFormatter, Btn, WishlistModal },
    })
], PackageCard);
export default PackageCard;
